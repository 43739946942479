export const words = {
  album_title: "Naam album",
  rename: "Hernoem",
  no_images: "Leeg",
  albums: "Albums",
  email: "e-mail",
  Email: "e-mail",
  password: "wachtwoord",
  login: "Inloggen",
  pictures: "Foto's",
  copy_link: "Link kopiëren",
  share_with_whatsapp: 'via WhatsApp',
  qrdownload: "Qr-Code Downloaden",
  flyerdownload: "Flyer downloaden",
  logout: "Afmelden",
  challenge: "Foto Uitdaging",
  preparation: "Voorbereiding",
  photos: "Foto's",
  challenges: "Uitdaging",
  diashow: "Live diavoorstelling",
  settings: "Instellingen",
  of: "van",
  comments: "Reacties",
  liked: "Zoals",
  answer: "Antwoorden",
  minutes: " minuten",
  hours: " Uren",
  days: " Dagen",
  yesterday: "Gisteren",
  hide: "Verberg",
  choose: "Selecteer",
  share: "Deel",
  report: "Rapporteer",
  delete: "Verwijder",
  description: "Beschrijving van de",
  enter: "Word lid van",
  install: "Installeer",
  or: "of",
  event: "evenement",
  save: "Sla",
  cancel: "Annuleren",
  continue: "Verder",
  task: "Taak",
  take_photo: "Maak een foto",
  skip: "Skip",
  color: "Kleur",
  videos: "Video's",
  video: 'Video',
  eur: 'Euro',
  usd: 'Dollar',
  packages: {
    free: "Gratis",
    base: 'Basis',
    upgrade_base_to_premium: 'Premium',
    upgrade_base_to_deluxe: 'Deluxe',
    premium: 'Premium',
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "A",
  2: "Twee",
  3: "Drie",
  4: 'Vier',
  5: "Vijf",
  download: "Download",
  are: "zijn",
  is: "is",
  mass_download: "Massa download",
  upgrade: 'Upgrade',
  start_now: "Start",
  live_diashow: "Live diavoorstelling",
  code: 'Code',
  selected: "Geselecteerd",
  chosen: "geselecteerd",
  all: 'Alle',
  picture: "Afbeelding",
  language: "Taal",
  languages: {
    de: "Duits",
    en: "Engels",
    fr: "Frans",
    it: "Italiaans",
    es: "Spaans"
  },
  close: "Sluit",
  edit: "Bewerk",
  release: "Vrijlaten",
  check: "Kijk op",
  Name: "Naam",
  area_of_work: "Industrie",
  instagram_name: "Instagram-naam",
  send: "Stuur",
  submit: "Bevestig"
};