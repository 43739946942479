export const words = {
  album_title: "Όνομα άλμπουμ",
  rename: "Μετονομασία",
  no_images: "Άδειο",
  albums: "Άλμπουμ",
  email: "e-mail",
  Email: "e-mail",
  password: "κωδικός πρόσβασης",
  login: "Συνδεθείτε",
  pictures: "Εικόνες",
  copy_link: "Αντιγραφή συνδέσμου",
  share_with_whatsapp: "μέσω WhatsApp",
  qrdownload: "Qr-Code Λήψη",
  flyerdownload: "Λήψη φυλλαδίου",
  logout: "Αποσύνδεση",
  challenge: "Πρόκληση φωτογραφίας",
  preparation: "Προετοιμασία",
  photos: "Φωτογραφίες",
  challenges: "Πρόκληση",
  diashow: "Ζωντανή παρουσίαση διαφανειών",
  settings: "Ρυθμίσεις",
  of: "από το",
  comments: "Σχόλια",
  liked: "Όπως",
  answer: "Απαντήσεις",
  minutes: " λεπτά",
  hours: " Ώρες",
  days: " Ημέρες",
  yesterday: "Χθες",
  hide: "Απόκρυψη",
  choose: "Επιλέξτε",
  share: "Μοιραστείτε το",
  report: "Αναφορά",
  delete: "Διαγραφή",
  description: "Περιγραφή του",
  enter: "Εγγραφείτε στο",
  install: "Εγκαταστήστε το",
  or: "ή",
  event: "εκδήλωση",
  save: "Αποθήκευση",
  cancel: "Ακύρωση",
  continue: "Περαιτέρω",
  task: "Εργασία",
  take_photo: "Βγάλτε μια φωτογραφία",
  skip: "Παράλειψη",
  color: "Χρώμα",
  videos: "Βίντεο",
  video: "Βίντεο",
  eur: "Ευρώ",
  usd: "Δολάριο",
  packages: {
    free: "Δωρεάν",
    base: "Βάση",
    upgrade_base_to_premium: 'Premium',
    upgrade_base_to_deluxe: 'Deluxe',
    premium: 'Premium',
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "A",
  2: "Δύο",
  3: "Τρεις",
  4: "Τέσσερις",
  5: "Πέντε",
  download: "Λήψη",
  are: "είναι",
  is: "είναι",
  mass_download: "Μαζική λήψη",
  upgrade: "Αναβάθμιση",
  start_now: "Έναρξη",
  live_diashow: "Ζωντανή παρουσίαση διαφανειών",
  code: "Κωδικός",
  selected: "Επιλεγμένα",
  chosen: "επιλεγμένο",
  all: "Όλα",
  picture: "Εικόνα",
  language: "Γλώσσα",
  languages: {
    de: "Γερμανικά",
    en: "Αγγλικά",
    fr: "Γαλλικά",
    it: "Ιταλικά",
    es: "Ισπανικά"
  },
  close: "Κλείστε το",
  edit: "Επεξεργασία",
  release: "Απελευθέρωση",
  check: "Ελέγξτε το",
  Name: "Όνομα",
  area_of_work: "Βιομηχανία",
  instagram_name: "Όνομα Instagram",
  send: "Στείλτε",
  submit: "Επιβεβαίωση"
};