export const words = {
  album_title: "Nome dell'album",
  rename: "Rinomina",
  no_images: "Vuoto",
  albums: "Album",
  email: "e-mail",
  Email: "e-mail",
  password: "password",
  login: "Accedi",
  pictures: "Immagini",
  copy_link: "Copia il link",
  share_with_whatsapp: 'via WhatsApp',
  qrdownload: 'Qr-Code Download',
  flyerdownload: "Scarica il volantino",
  logout: "Esci",
  challenge: "Sfida fotografica",
  preparation: "Preparazione",
  photos: "Foto",
  challenges: "Sfida",
  diashow: "Presentazione in diretta",
  settings: "Impostazioni",
  of: "da",
  comments: "Commenti",
  liked: "Come",
  answer: "Risposte",
  minutes: " minuti",
  hours: " Ore",
  days: " Giorni",
  yesterday: "Ieri",
  hide: "Nascondi",
  choose: "Seleziona",
  share: "Condividi",
  report: "Rapporto",
  delete: "Elimina",
  description: "Descrizione del",
  enter: "Unisciti a noi",
  install: "Installa",
  or: "o",
  event: "evento",
  save: "Risparmia",
  cancel: "Annullamento",
  continue: "Ulteriori informazioni",
  task: "Compito",
  take_photo: "Scatta una foto",
  skip: "Salta",
  color: "Colore",
  videos: "Video",
  video: 'Video',
  eur: 'Euro',
  usd: "Dollaro",
  packages: {
    free: "Gratis",
    base: "Base",
    upgrade_base_to_premium: 'Premium',
    upgrade_base_to_deluxe: 'Deluxe',
    premium: 'Premium',
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "A",
  2: "Due",
  3: "Tre",
  4: "Quattro",
  5: "Cinque",
  download: "Scarica",
  are: "sono",
  is: "è",
  mass_download: "Download di massa",
  upgrade: "Aggiornamento",
  start_now: "Iniziare",
  live_diashow: "Presentazione in diretta",
  code: "Codice",
  selected: "Selezionato",
  chosen: "selezionato",
  all: "Tutti",
  picture: "Immagine",
  language: "Lingua",
  languages: {
    de: "Tedesco",
    en: "Inglese",
    fr: "Francese",
    it: "Italiano",
    es: "Spagnolo"
  },
  close: "Chiudi",
  edit: "Modifica",
  release: "Rilascio",
  check: "Controllo",
  Name: "Nome",
  area_of_work: "Industria",
  instagram_name: "Nome Instagram",
  send: "Invia",
  submit: "Confermare"
};