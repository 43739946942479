export const words = {
  album_title: "Albüm adı",
  rename: "Yeniden Adlandır",
  no_images: "Boş",
  albums: "Albümler",
  email: "e-posta",
  Email: "e-posta",
  password: "şifre",
  login: "Giriş yap",
  pictures: "Resimler",
  copy_link: "Bağlantıyı kopyala",
  share_with_whatsapp: "WhatsApp aracılığıyla",
  qrdownload: "Qr-Code İndir",
  flyerdownload: "El İlanı İndir",
  logout: "Oturumu kapat",
  challenge: "Fotoğraf Yarışması",
  preparation: "Hazırlık",
  photos: "Fotoğraflar",
  challenges: "Meydan Okuma",
  diashow: "Canlı slayt gösterisi",
  settings: "Ayarlar",
  of: "gelen",
  comments: "Yorumlar",
  liked: "Gibi",
  answer: "Cevaplar",
  minutes: " dakika",
  hours: " Saatler",
  days: " Günler",
  yesterday: "Dün",
  hide: "Gizle",
  choose: "Seçiniz",
  share: "Paylaş",
  report: "Rapor",
  delete: "Silme",
  description: "Açıklama",
  enter: "Katılın",
  install: "Kurulum",
  or: "veya",
  event: "olay",
  save: "Kaydet",
  cancel: "İptal",
  continue: "Daha ileri",
  task: "Görev",
  take_photo: "Bir fotoğraf çekin",
  skip: "Atla",
  color: "Renk",
  videos: "Videolar",
  video: 'Video',
  eur: 'Euro',
  usd: "Dolar",
  packages: {
    free: "Ücretsiz",
    base: "Üs",
    upgrade_base_to_premium: 'Premium',
    upgrade_base_to_deluxe: 'Deluxe',
    premium: 'Premium',
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "A",
  2: "İki",
  3: "Üç",
  4: "Dört",
  5: "Beş",
  download: "İndir",
  are: "vardır",
  is: "o",
  mass_download: "Toplu indirme",
  upgrade: "Yükseltme",
  start_now: "Başlangıç",
  live_diashow: "Canlı slayt gösterisi",
  code: "Kod",
  selected: "Seçilmiş",
  chosen: "seçilmiş",
  all: "Tümü",
  picture: "Resim",
  language: "Dil",
  languages: {
    de: "Alman",
    en: "İngilizce",
    fr: "Fransızca",
    it: "İtalyan",
    es: "İspanyolca"
  },
  close: "Kapat",
  edit: "Düzenle",
  release: "Serbest Bırak",
  check: "Kontrol et",
  Name: "İsim",
  area_of_work: "Endüstri",
  instagram_name: "Instagram adı",
  send: "Gönder",
  submit: "Onaylayın"
};