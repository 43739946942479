export const words = {
  album_title: "Album name",
  rename: "Rename",
  no_images: "Empty",
  albums: "Albums",
  email: "Email",
  Email: "Email",
  password: "Password",
  login: "Log in",
  pictures: "Pictures",
  copy_link: "Copy Link",
  share_with_whatsapp: 'via WhatsApp',
  qrdownload: "QR Code Download",
  flyerdownload: "Download flyer",
  logout: "Log out",
  challenge: "Photo Challenge",
  preparation: "Preparation",
  photos: "Photos",
  challenges: "Challenges",
  diashow: "Live Slideshow",
  settings: "Settings",
  of: "by",
  comments: "Comments",
  liked: "Liked",
  answer: "Reply",
  minutes: "Minutes",
  hours: "Hours",
  days: "Days",
  yesterday: "Yesterday",
  hide: "Hide",
  choose: "Select",
  share: "Share",
  report: "Report",
  delete: "Delete",
  description: "Description",
  enter: "Join",
  install: "Install",
  or: "or",
  event: "Event",
  save: "Save",
  cancel: "Cancel",
  continue: "Continue",
  task: "Task",
  take_photo: "Take Photo",
  skip: "Skip",
  color: "Color",
  videos: 'Videos',
  video: 'Video',
  eur: 'Euro',
  usd: 'Dollar',
  packages: {
    free: 'Free',
    base: "Basic",
    upgrade_base_to_premium: 'Premium',
    upgrade_base_to_deluxe: 'Deluxe',
    premium: 'Premium',
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  download: "Download",
  are: "are",
  is: "is",
  mass_download: "Bulk Download",
  upgrade: 'Upgrade',
  start_now: "Start Now",
  live_diashow: "Live Slideshow",
  code: 'Code',
  selected: "Selected",
  chosen: "selected",
  all: "All",
  picture: "picture",
  language: "Language",
  languages: {
    de: "German",
    en: "English",
    fr: "French",
    it: "Italian",
    es: "Spanish"
  },
  close: "Close",
  edit: "Edit",
  release: "Release",
  check: "Check",
  Name: 'Name',
  area_of_work: "Industry",
  instagram_name: "Instagram name",
  send: "Submit",
  submit: "Confirm"
};