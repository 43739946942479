export const phrases = {
  unassign_from_album: "Do you really want to remove the selected images from the album?",
  remove_media_from_album: "Remove from album",
  assign_to_which_album: "Which album would you like to add the selected images to?",
  rename_album_title: "You can edit your album here",
  name_of_album: "What will your new album be called?",
  create_album: "New album",
  assign_media_to_album: "Add to an album",
  your_party: "Your party.",
  all_pictures: "All pictures.",
  forgot_password: "Forgot password?",
  no_account_yet: "Don't have an account yet? Register now.",
  send_your_event: "Invite your guests",
  scan_here: "Scan here",
  show_more_replies: "view more replies",
  shared_image_text: "Check out this picture from an event on https://www.everlense.de",
  image_not_available_anymore: "This picture is no longer available",
  add_images: "Add pictures",
  capture_photo: "Take Photo",
  enter_your_name: "Enter your name",
  your_photo_app_for_weddings: "Your photo app for weddings",
  invite_guests: "Invite guests",
  delete_demo_data: "Delete demo data",
  event_description_placeholder: "What else do you want to tell your guests? Go to settings to adjust the description.",
  start_slideshow: "Start slideshow here",
  slideshow_steps_1: "Go to",
  slideshow_steps_2: "and enter the following",
  slideshow_steps_3: "6-digit code",
  slideshow_steps_4: ".",
  print_tasks: "Print tasks",
  edit_tasks: "Manage tasks",
  challenge_tips_and_tricks_1: "Tips & tricks for",
  challenge_tips_and_tricks_2: "your perfect challenge",
  challenge_tips_and_tricks_content_1: "Print your template on thick paper.",
  challenge_tips_and_tricks_content_2: "Distribute the tasks at your party at each place.",
  challenge_tips_and_tricks_content_3: "Tell as many people as possible about the photo challenge.",
  challenge_tips_and_tricks_content_4: "Use the live slideshow so all guests can see the snapshots in real time.",
  print_tasks_intro: "To ensure your guests find the photo challenge tasks, you should lay them out at the party.",
  print_tasks_title_1: "Cards for printing (popular)",
  print_tasks_title_2: "Export QR codes only (and design cards yourself)",
  print_tasks_action_1: "Download PDFs",
  print_tasks_action_2: "Download QR codes",
  print_tasks_description_1: "Guests can join the photo challenge through the QR codes on our printable cards.",
  print_tasks_description_2: "Download QR codes to start the photo challenge and use them for self-designed cards. There is a QR code for each task.",
  edit_tasks_title_1: "Choose the tasks",
  edit_tasks_title_2: "for your guests",
  really_want_to_delete_task: "Really delete task?",
  add_task_button: "Add task",
  delete_all_tasks_button: "Delete all",
  settings_albums: "Album settings",
  upload_to_which_album: "Which album should your guests' photos be automatically uploaded to?",
  restrict_access_to_album: "My guests can only access the selected album",
  settings_event_name_header: "What should your event be called?",
  settings_event_description_header: "What else do your guests need to know?",
  settings_event_name_label: "Event name",
  settings_event_description_label: "Event description",
  settings_password_title: "Change password",
  settings_old_password_header: "Old password",
  settings_new_password_header: "New password",
  settings_new_password_repeat_header: "Repeat new password",
  settings_invite_admin_title: "Add admins",
  settings_invite_admin_header: "New admin's email address",
  send_invite: "Send invite",
  settings_deactivate_challenge_title: "Deactivate challenge",
  settings_deactivate_challenge_header: "Activate/Deactivate the challenge",
  settings_delete_data_title: "Delete account",
  settings_delete_data_header: "Permanently delete all data",
  delete_event: "Delete event",
  delete_event_toast_content: "Click \"Delete\" to permanently close your account. WARNING: All uploaded pictures, videos, and data will be irretrievably lost and cannot be restored by the everlense team.",
  settings_legal: "Legal",
  settings_legal_content_1: "By creating your event, you have accepted our",
  settings_legal_content_2: "Terms and Conditions",
  settings_legal_content_3: " and our",
  settings_legal_content_4: "Privacy Policy",
  settings_legal_content_5: ". If you want to learn more about everlense, visit our ",
  settings_legal_content_6: "Legal Notice.",
  reset_password: "Reset password",
  register_phrase: "To complete the setup of your event, we need your email address.",
  register_phrase_booked: "Booking was successful! To complete the setup of your event, we need your email address.",
  email_address: "Email address",
  register_legal: "By joining, you accept our <a class=\"underline\" href=\"https://www.everlense.de/agb\">terms and conditions</a> and our <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">privacy policy</a>. If you would like to find out more about everlense, visit our <a class=\"underline\" href=\"https://www.everlense.de/impressum\">legal notice</a>. By signing up, you also agree to receive marketing emails from us. You can revoke this consent at any time.",
  install_everlense: "Install everlense",
  install_content_1: "Install everlense on your home screen so you never forget the event.",
  install_content_2: "Click",
  install_content_3: "and then \"Add to Home Screen\".",
  install_content_4: "Later",
  install_content_5: "Got it!",
  no_images_yet: "No pictures have been uploaded yet. Press the plus to upload a picture.",
  delete_all_tasks_really: "Really delete all tasks?",
  your_task: "Your task",
  change_colors: "Change colors",
  primary_color: "Primary color",
  background_color: "Background color",
  reset: "Reset",
  choose_color: "Choose color",
  upgrade_package: "Upgrade package",
  upgrade_required: "Upgrade required",
  current_package: "Current package",
  used_image_count: "{used} of {max} pictures used.",
  deleted_image_count: "{not_deleted} currently in your gallery, {deleted} deleted.",
  used_downloads_count: "{used} of {max} bulk downloads used.",
  most_popular: "Most popular",
  price: "{price} Euros",
  max_media: "max. {count} pictures",
  max_guests: "max. {count} guests",
  infinite_guests: "Unlimited number of guests",
  storage_duration: "{duration} months of storage",
  max_bulk_downloads: "free bulk download | free bulk downloads",
  custom_colors: "Custom colors",
  multiple_admins: "Multiple admins",
  whatsapp_support: 'Premium WhatsApp Support',
  go_to_checkout: "To checkout",
  start_setup: "Start setup",
  upgrade_toast_content: "To use this feature, you need to upgrade your everlense account. Check out the different options.",
  no_thank_you: "No, thank you.",
  i_want_to_know_more: "Tell me more",
  demo_event_title: 'Lisa & Jan',
  how_do_you_want_to_reedem: "How do you want to redeem your package?",
  currently_logged_in_event: "You are currently logged into the event: {name}. Would you like to apply your purchase to this event?",
  choose_event: "Which event do you want to apply your purchase to?",
  use_for_current_event: "current event",
  use_for_existing_event: "existing event",
  use_for_new_event: "new event",
  settings_event_date_header: "When is your event taking place?",
  settings_event_date_label: "Event date",
  sort: "Sort",
  time_of_recording: "Time of recording",
  time_of_upload: "Time of upload",
  lets_setup_event: "Let's set up your event together",
  we_will_help_you_setup: "We will help you set up your everlense event in just 2 minutes. Are you interested?",
  what_is_the_name: "What should the name of your event be?",
  cancel_setup: "Cancel setup",
  enter_event_name_here: "Enter event name here",
  when_does_event_take_place: "When is your event taking place?",
  enter_event_date_here: "Enter event date here",
  nice_please_upload_photo: "Great. Now upload a photo to make it more personal",
  select_photo: "Select photo",
  what_do_you_want_to_describe: "Wow! Do you want to tell your guests anything else?",
  enter_description_here: "Enter description here",
  finish_setup: "Finish setup",
  successfull_save: "Successfully saved",
  successfull_deletion: "Successfully deleted",
  copied_link: "Link copied",
  booking_successfull: "Booking successful",
  completed_setup: "Setup completed",
  sent_invite: "Invite sent",
  initiated_payout: "Payout initiated",
  sent_email: "Email sent",
  max_ten_images: "A maximum of 10 pictures at once.",
  reached_download_limit: "Event download limit reached.",
  reached_mass_download_limit: "Maximum number of bulk downloads reached.",
  no_images_were_uploaded: "No pictures were uploaded.",
  booking_failed: "Booking failed.",
  package_already_retrieved: "Package already redeemed",
  download_all_pictures: "Download all pictures",
  notice: "Notice",
  in_your_package: "In your package",
  bulk_download_modal_2: "of all photos at once included. If you click \"Download\" now, you will use",
  bulk_download_modal_3: "one of these",
  bulk_download_modal_4: "this",
  bulk_download_modal_5: 'Download',
  bulk_download_modal_6: "You have used all the bulk downloads of your package. Please book an upgrade to download all pictures.",
  bulk_download_modal_7: "Please wait. Your pictures are being prepared for download.",
  donwload_modal_1: '',
  donwload_modal_2: "?",
  delete_modal_1: '',
  delete_modal_2: "?",
  download_preparation: "Your download is being prepared. You will receive an email with your personal download link within 15 minutes.",
  save_pictures: "Save pictures",
  delete_pictures: "Delete pictures",
  upload_in_progress: "Uploading",
  change_language: "Change language",
  change_language_info: "Your guests can use everlense in English and German. The language automatically adjusts to the respective device of the guest.",
  multiple_languages_1: "Multiple languages",
  multiple_languages_2: "(English & German)",
  okay: "Got it!",
  download_pictures: "Download pictures",
  max_ten_images_go_to_settings: "A maximum of 10 images allowed. Go to settings to download all images.",
  your_images_are_ready: "Yay, your pictures are ready!",
  here_are_your_images: "Here are the pictures of your event {event}. Have fun!",
  ps_mass_download: "PS: Simply download the parts one after another. This will only be counted as one bulk download.",
  download_here: "Download here",
  download_part: "Download part {part}",
  switch_language: "Change language",
  tasks_choose_language: "What language should the sample tasks have?",
  successfull_task_recreation: "Language of the example tasks has been changed",
  something_went_wrong: "Something has gone wrong",
  a_small_present_for_you: "A little present for you!",
  present_description: "Would you like to use everlense at your event? <span class=\"font-medium\">Then we have a surprise for you</span>. We'll send it to your e-mail address.",
  your_email_address: "Your e-mail address",
  save_discount: "Accept",
  accept_agb_and_mail: "I agree to the <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">terms and conditions</a> and would like to receive emails from everlense.",
  thank_you_for_sending: "We will send you an e-mail with your personal surprise within the next 15 minutes.",
  discount_saved: "Surprise secured!",
  was_reported: "A guest has reported this {type} .",
  what_shall_happen: "What should happen to this {type} ?",
  edit_reporting_description: "Please check the {type} and decide whether it should be released again or permanently deleted. We have hidden the image in the meantime.",
  how_did_you_get_to_know_us: "How did you hear about everlense?",
  please_select: "Please select",
  friends_and_family: "Friends & acquaintances",
  seen_at_a_wedding: "Seen at a wedding",
  other: "Miscellaneous",
  settings_deactive_challenge_text_download: "Label images during download",
  your_payment_is_processing: "Your payment is being processed. Please be patient for a moment.",
  coupon_code: "Voucher code",
  what_discount_code: "What should your individual discount code be called?",
  message_to_everlense_team: "Message to the everlense team"
};